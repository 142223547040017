export function menu(wrapper = "main-menu") {
  const mainNavigationWrapper = document.querySelector("." + wrapper);

    //   const hamburger = mainNavigationWrapper.querySelector(".hamburger");
  //   hamburger.addEventListener("click", () => {
  //     if (hamburger.classList.contains("is-open")) {
  //       hamburger.classList.remove("is-open");
  //       mainNavigationWrapper
  //         .querySelector(".navigation-wrapper")
  //         .classList.remove("translate-x-0");
  //       mainNavigationWrapper
  //         .querySelector(".navigation-wrapper")
  //         .classList.add("translate-x-full");

  //       // Remove Blow chunk if client wants Sticky / Fixed menu
  //       document
  //         .querySelector("body")
  //         .classList.remove("max-h-screen", "overflow-hidden");
  //     } else {
  //       hamburger.classList.add("is-open");
  //       mainNavigationWrapper
  //         .querySelector(".navigation-wrapper")
  //         .classList.add("translate-x-0");
  //       mainNavigationWrapper
  //         .querySelector(".navigation-wrapper")
  //         .classList.remove("translate-x-full");

  //       // Remove Blow chunk if client wants Sticky / Fixed menu
  //       // Scroll to top in case user scroll down just a bit and then click on menu toggle.
  //       window.scrollTo({ top: 0, behavior: "smooth" });
  //       document
  //         .querySelector("body")
  //         .classList.add("max-h-screen", "overflow-hidden");
  //     }
  //   });

  // ✅ Exit early if the element does not exist
  if (!mainNavigationWrapper) {
    console.warn(`Warning: Element with class '${wrapper}' not found.`);
    return; // Stop execution
  }

  // ✅ Safe way to select navigation items
  let parentNavigationItemsForMobile = mainNavigationWrapper.querySelectorAll(
    ".js-toggle-mobile-inner-nav"
  );

  parentNavigationItemsForMobile.forEach(function (item) {
    item.addEventListener("click", () => {
      const subMenu = item.parentElement.nextElementSibling;
      const icon = item.querySelector("svg");

      if (!subMenu) return; // ✅ Prevent errors if submenu doesn't exist

      if (subMenu.classList.contains("is-open")) {
        subMenu.classList.remove("is-open");
        subMenu.removeAttribute("style");
        icon?.classList.remove("rotate-180");
      } else {
        subMenu.classList.add("is-open");
        subMenu.style.height = subMenu.scrollHeight + "px";
        icon?.classList.add("rotate-180");
      }
    });
  });
}
